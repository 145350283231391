export abstract class EntityConstraints {
  static readonly Organization = { NameMaxLength: 100 };
  static readonly User = {
    SearchMaxLength: 256,
    EmailMaxLength: 320,
    DisplayNameMaxLength: 256,
    FamilyNameMaxLength: 256,
    GivenNameMaxLength: 256,
  };
  static readonly Group = { NameMaxLength: 100, DescriptionMaxLength: 100 };
  static readonly Role = { NameMaxLength: 100, DescriptionMaxLength: 100 };
  static readonly App = { NameMaxLength: 200, AudienceMaxLength: 100 };
  static readonly Client = { NameMaxLength: 100, RedirectUriMaxLength: 400 };
  static readonly Secret = { DescriptionMaxLength: 100 };
  static readonly Scope = {
    ValueMaxLength: 100,
    ValueFormat: '^([a-z]+(_[a-z]+)*[/]*)+:[a-z]+(_[a-z]+)*',
    DescriptionMaxLength: 200,
  };
  static readonly ScopeParameterName = { NameMaxLength: 16, NameMinLength: 5, DescriptionMaxLength: 100 };
  static readonly ScopeParameterValue = { ValueMaxLength: 36, DescriptionMaxLength: 100 };
}
